import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, ButtonGroup, Button, Card, Carousel } from "react-bootstrap"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import logo from "../images/digital-desk-text.svg"

const StaticIndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />
      <section className="py-5">
        <Container>
          <Row className="mb-5">
            <Col className="text-center">
              <figure className="m-0">
                <img alt="Digital Desk" src={logo} />
              </figure>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={{ span: 10, offset: 1 }} className="text-center">
              <h2 className="m-0 font-weight-light">
                Still spamming your investment client? Use your own digital portal to improve your <strong className="text-primary">conversion rate</strong> and build a more valuable relationship.
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 6, offset: 3 }} className="d-flex flex-column">
              <ButtonGroup size="lg">
                <Button className="w-50" variant="primary">Request a callback</Button>
                <Button className="w-50" variant="info">Try the demo</Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 bg-primary text-white shadow--inset">
        <Container>
          <h2 className="h2 mb-4">Why Digitise?</h2>
          <Row className="align-items-center">
            <Col md={6} className="mb-4 mb-md-0">
              <ul className="h3 list-unstyled font-weight-light">
                <li className="mb-3 d-flex align-items-center"><span className="text-info fas fa-check mr-3"></span>Increase trade flow and conversion rate</li>
                <li className="mb-3 d-flex align-items-center"><span className="text-info fas fa-check mr-3"></span>Strengthen relationships with your clients</li>
                <li className="d-flex align-items-center"><span className="text-info fas fa-check mr-3"></span>Take ownership of your digital portal</li>
              </ul>
            </Col>
            <Col md={6}>
              <Card text="info">
                <Card.Body>
                  <p>Most trading desks routinely create high quality <strong>sales notes, reports and periodicals</strong> but only distribute these via email to their clients.</p>
                  <p className="mb-0">Digital Desk allows these <strong>same publications</strong> to be read by your client in a way that suits them, at the same time allowing the desk to receive <strong>improved feedback</strong> and client insight.</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <h2 className="h2 mb-4">How does it work?</h2>
          <Row className="align-items-end mb-3">
            <Col md={4} className="d-none d-md-block text-right">
              <span className="text-info fas fa-arrow-up fa-2x tilt-right"></span>
            </Col>
            <Col md={4} className="d-flex flex-column text-center pb-md-5">
              <span className="fas fa-upload fa-3x text-primary mb-3"></span>
              <div className="mb-3">
                <span className="mx-1 text-primary far fa-2x fa-file-pdf"></span>
                <span className="mx-1 text-primary far fa-2x fa-file-excel"></span>
                <span className="mx-1 text-primary far fa-2x fa-file-image"></span>
                <span className="mx-1 text-primary far fa-2x fa-file-video"></span>
              </div>
              <h4>Publish</h4>
              <p>Drag and drop <strong>existing content</strong> to your digital portal. It is analysed and indexed making it <strong>highly searchable</strong></p>
            </Col>
            <Col md={4} className="d-none d-md-block text-left">
              <span className="text-info fas fa-arrow-down fa-2x tilt-left"></span>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={{ span: 4, order: 3 }} className="d-flex flex-column text-center">
              <span className="far fa-bell fa-5x text-primary mb-3"></span>
              <h4>Notify</h4>
              <p>Intelligently inform your clients providing them with <strong>easy access</strong> to <strong>relevant content</strong></p>
            </Col>
            <Col md={{ span: 2, order: 2 }} className="d-none d-md-block text-center">
              <span className="text-info fas fa-arrow-left fa-2x"></span>
            </Col>
            <Col md={{ span: 4, order: 1, offset: 1 }} className="d-flex flex-column text-center">
              <span className="far fa-comments fa-5x text-primary mb-3"></span>
              <h4>Feedback</h4>
              <p><strong>Optimise sales</strong> based on advanced <strong>client engagement</strong> metrics</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 bg-info text-white shadow--inset">
        <Container>
          <h2 className="h2 mb-4">Is it worth it?</h2>
          <Carousel className="py-3">
            <Carousel.Item>
              <blockquote className="blockquote m-0">
                <p className="h4 font-weight-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                <cite className="text-muted">- A. Name</cite>
              </blockquote>
            </Carousel.Item>
            <Carousel.Item>
              <blockquote className="blockquote m-0">
                <p className="h4 font-weight-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                <cite className="text-muted">- A. Name</cite>
              </blockquote>
            </Carousel.Item>
            <Carousel.Item>
              <blockquote className="blockquote m-0">
                <p className="h4 font-weight-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                <cite className="text-muted">- A. Name</cite>
              </blockquote>
            </Carousel.Item>
          </Carousel>
        </Container>
      </section>
      <section className="pt-5">
        <Container>
          <h2 className="h2 mb-4">What do your clients get?</h2>
          <Row className="align-items-center">
            <Col md={6} className="mb-4 mb-md-0">
              <ul className="h3 list-unstyled font-weight-light">
                <li className="mb-3 d-flex align-items-center">
                  <span className="text-primary fas fa-check mr-3"></span>Optimised mobile and tablet access
                </li>
                <li className="mb-3 d-flex align-items-center">
                  <span className="text-primary fas fa-check mr-3"></span>Search on contents of published files
                </li>
                <li className="d-flex align-items-center">
                  <span className="text-primary fas fa-check mr-3"></span>Save, share and download publications
                </li>
              </ul>
            </Col>
            <Col md={6}>
              <Card text="white" bg="info">
                <Card.Body>
                  <p>Your clients can expect <strong>fast access</strong> to all of your content via a dedicated portal, <strong>optimised for mobile</strong> and tablet performance.</p>
                  <p className="mb-0">They can add content to a reading list, rate it, share it or download it, providing you with insights into these interactions to help increase conversion rates.</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col md={{ span: 4, offset: 4 }}>
              <Img fluid={data.devicesImage.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 bg-primary text-white shadow--inset">
        <Container>
          <h2 className="h2 mb-4">Get on board</h2>
          <p className="lead">Getting started is simple - speak to one of our team today to begin the onboarding process.</p>
          <p className="mb-5">We will work with you to provide a pricing model that suits the size of your business, but all our customers recieve full support throughout portal setup, including hosting, user migration and design customization.</p>
          <Row>
            <Col md={{ span: 2, offset: 5 }} className="d-flex flex-column">
              <Button size="lg" variant="info">Call now</Button>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default StaticIndexPage

export const query = graphql `
  query StaticIndexQuery {
    devicesImage: file(relativePath: { eq: "images/devices.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
